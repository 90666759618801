<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold pa-0"
        >Form Questions ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search form question, description, or options"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn icon class="ml-5" @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <div class="d-flex mt-6">
      <div>
        <v-btn
          color="primary"
          @click="
            $router.push({
              name: 'formQuestion.new'
            })
          "
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon
          >Add new form question
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex">
        <v-select
          class="ml-4"
          hide-details
          flat
          solo
          dense
          label="Milestone"
          item-text="name"
          item-value="id"
          clearable
          v-model="filter.milestone"
          :items="milestones"
          :loading="loading"
          style="max-width: 200px;"
          @change="filterFormQuestion"
        />

        <v-select
          class="ml-4"
          hide-details
          flat
          solo
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px;"
          @change="filterFormQuestion"
        />

        <v-btn
          icon
          class="ml-3"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterFormQuestion()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>

        <v-btn
          icon
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterFormQuestion()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      class="mt-6"
      id="formQuestion-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table dense>
        <thead>
          <tr>
            <th width="15%" class="text-left">Milestone</th>
            <th width="25%" class="text-left">Body</th>
            <th width="25%" class="text-left">Description</th>
            <th width="25%" class="text-left">Choices</th>
            <th width="10%" class="text-left">Is Multiple Choice?</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="formQuestion in formQuestions"
            :key="formQuestion.id"
            class="clickable"
            @click="
              $router.push({
                name: 'formQuestion.details',
                params: { id: formQuestion.id }
              })
            "
          >
            <td width="15%">
              {{ formQuestion.milestone ? formQuestion.milestone.name : '' }}
            </td>
            <td width="25%">{{ formQuestion.body }}</td>
            <td width="25%">{{ formQuestion.description }}</td>
            <td width="25%">
              <ul>
                <li v-for="choice in formQuestion.choices" :key="choice.id">
                  {{ choice.name }}
                </li>
              </ul>
            </td>
            <td width="10%">{{ formQuestion.is_multiple ? 'Yes' : 'No' }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td v-for="i in 5" :key="i" class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(formQuestions.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiPlus
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'FormQuestionsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
        milestone: ''
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      defaultFilter: 'body',
      sortOrder: true,
      filterBy: [
        {
          value: 'body',
          label: 'Body'
        },
        {
          value: 'description',
          label: 'Description'
        }
      ]
    }
  },

  created() {
    this.clearFormQuestions()
    this.fetchFormQuestions(1)
    this.$store.dispatch('milestone/getMilestones')
  },

  updated() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      })
    })
  },

  computed: {
    ...mapState({
      formQuestions: state => state.formQuestion.list,
      listMeta: state => state.formQuestion.listMeta,
      milestones: state => state.milestone.list
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },

  methods: {
    ...mapActions({
      getFormQuestions: 'formQuestion/getFormQuestions',
      exportToExcel: 'formQuestion/exportToExcel'
    }),

    ...mapMutations({
      clearFormQuestions: 'formQuestion/clearFormQuestionList'
    }),

    pageChanged(page) {
      this.fetchFormQuestions(page)
    },

    filterFormQuestion(filterValue) {
      this.clearFormQuestions()
      this.fetchFormQuestions(null, filterValue)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchFormQuestions(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function() {
      this.clearFormQuestions()
      this.fetchFormQuestions()
    }, 600),

    async fetchFormQuestions(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      if (this.filter.milestone) {
        params.milestone = this.filter.milestone
      }
      this.loading = true
      await this.getFormQuestions(params)
      this.loading = false
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel()
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'form_questions_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    }
  }
}
</script>
