<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'formQuestions' }"
          >Form Questions</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div class="my-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-form ref="form" class="form-question-form">
              <label class="text-field-label">Milestone</label>
              <v-select
                flat
                solo
                required
                class="mt-2"
                item-text="name"
                item-value="id"
                v-model="form.milestone"
                :items="milestones"
                :loading="loading"
                :error-messages="form.$getError('milestone')"
              />

              <label class="text-field-label">Form Question</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.body"
                :error-messages="form.$getError('body')"
                :loading="loading"
              ></v-text-field>

              <label class="text-field-label">Description</label>
              <v-textarea
                flat
                solo
                required
                rows="3"
                class="mt-2"
                v-model="form.description"
                :error-messages="form.$getError('description')"
                counter
                maxlength="255"
                hide-details="auto"
                :loading="loading"
                auto-grow
              ></v-textarea>

              <label class="text-field-label">Choices</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                hide-details="auto"
                v-model="choiceInput"
                :loading="loading"
                @keydown.enter="onAddChoice"
                :error-messages="form.$getError('choices')"
              ></v-text-field>

              <v-expansion-panels
                class="mt-2"
                :class="form.choices.length > 0 ? 'mb-8' : 'mb-6'"
              >
                <v-expansion-panel
                  v-for="(choice, cIndex) in form.choices"
                  :key="cIndex"
                >
                  <v-expansion-panel-header>
                    {{ choice.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <div>
                        <v-autocomplete
                          style="z-index:100"
                          flat
                          solo
                          dense
                          required
                          class="mt-2"
                          hide-details
                          v-model="resourceInput"
                          :loading="resourceIsLoading"
                          label="Enter related resources"
                          item-text="name"
                          item-value="id"
                          :items="resourcesItems"
                          :search-input.sync="searchResourceName"
                          return-object
                          @change="onAddResource(cIndex)"
                        ></v-autocomplete>
                      </div>
                      <v-list flat class="pa-0 mt-2 mb-5">
                        <v-list-item
                          class="pa-0"
                          v-for="(resource, rIndex) in choice.resources"
                          :key="rIndex"
                        >
                          <v-list-item-content class="px-0">
                            <v-list-item-title class="text-wrap">
                              <router-link
                                class="text-decoration--none"
                                target="_blank"
                                :to="{
                                  name: 'milestone.resources.details',
                                  params: { id: resource.id }
                                }"
                                >{{ resource.name }}</router-link
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon
                            class="my-3"
                            @click="onRemoveResource(cIndex, rIndex)"
                          >
                            <v-icon color="error">{{ icons.delete }}</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div class="text-right my-2">
                      <v-btn color="error" small @click="onRemoveChoice(cIndex)"
                        >Remove Choice</v-btn
                      >
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <label class="text-field-label">Is Multiple Choice?</label>
              <v-select
                flat
                solo
                required
                class="mt-2"
                v-model="form.is_multiple"
                :items="[
                  { value: 1, label: 'Yes' },
                  { value: 0, label: 'No' }
                ]"
                item-value="value"
                item-text="label"
                :error-messages="form.$getError('is_multiple')"
                :loading="form.$busy"
              ></v-select>

              <div class="d-flex mt-12">
                <v-btn
                  v-if="form.id"
                  color="error"
                  class="mr-4 px-6"
                  height="40px"
                  :loading="form.$busy"
                  @click="deleteDialog = true"
                  >Delete</v-btn
                >

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  class="px-6"
                  height="40px"
                  @click="
                    formQuestion
                      ? updateFormQuestionDetails()
                      : addFormQuestion()
                  "
                  :loading="form.$busy"
                  >Save</v-btn
                >
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>

            <v-card-text>Are you sure you want to delete this?</v-card-text>

            <v-card-actions>
              <v-btn text @click="deleteDialog = false" :loading="form.$busy"
                >Cancel</v-btn
              >

              <v-spacer></v-spacer>

              <v-btn
                color="red"
                text
                @click="removeFormQuestion"
                :loading="form.$busy"
                >Yes, Proceed</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiMinusCircle
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import debounce from 'lodash/debounce'

export default {
  name: 'FormQuestionDetails',

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        delete: mdiMinusCircle
      },
      loading: false,
      deleteDialog: false,
      form: new Form({
        id: '',
        body: '',
        description: '',
        choices: [],
        is_multiple: '',
        milestone: '',
        type: 'milestone',
        type_id: ''
      }),
      choiceInput: '',
      resourceInput: '',
      resourceIsLoading: '',
      searchResourceName: '',
      attachments: [],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      showModal: false
    }
  },

  computed: {
    ...mapState({
      formQuestion: state => state.formQuestion.formQuestionDetails,
      authUser: state => state.auth.user,
      milestones: state => state.milestone.list,
      resources: state => state.resource.list
    }),

    displayName() {
      return this.form.body
    },

    resourcesItems() {
      return this.resources.map(resource => ({
        id: resource.id,
        name: resource.title
      }))
    }
  },

  methods: {
    ...mapActions({
      getFormQuestionDetails: 'formQuestion/getFormQuestionDetails',
      createFormQuestion: 'formQuestion/createFormQuestion',
      updateFormQuestion: 'formQuestion/updateFormQuestion',
      deleteFormQuestion: 'formQuestion/deleteFormQuestion',
      getResources: 'resource/getResources'
    }),

    ...mapMutations({
      clearFormQuestionDetails: 'formQuestion/clearFormQuestionDetails'
    }),

    loadAttachments() {
      this.form.attachments = this.attachments
    },

    onAddChoice() {
      if (this.choiceInput) {
        this.form.choices.push({ name: this.choiceInput, resources: [] })
      }
      this.choiceInput = ''
    },

    onRemoveChoice(index) {
      this.form.choices.splice(index, 1)
    },

    onAddResource(cIndex) {
      this.form.choices[cIndex].resources.push(this.resourceInput)
    },

    onRemoveResource(cIndex, rIndex) {
      this.form.choices[cIndex].resources.splice(rIndex, 1)
    },

    async getFormQuestion() {
      this.loading = true
      await this.getFormQuestionDetails(this.$route.params.id)
      this.formQuestion.type = 'milestone'
      this.formQuestion.type_id = this.formQuestion.milestone.id
      this.formQuestion.choices = this.formQuestion.choices
        ? this.formQuestion.choices.map(o => ({
            name: o.name,
            resources: o.resources
              ? o.resources.map(r => ({
                  id: r.id,
                  name: r.title
                }))
              : []
          }))
        : []

      this.form = new Form(this.formQuestion)
      delete this.form.tips
      delete this.form.sub_questions
      delete this.form.project_type
      delete this.form.order
      this.loading = false
    },

    async updateFormQuestionDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      delete this.form.milestone

      this.form.choices = this.form.choices.map(choice => ({
        name: choice.name,
        resource_ids: choice.resources ? choice.resources.map(r => r.id) : []
      }))

      this.updateFormQuestion(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar(
            'Form question details successfully updated!',
            'success'
          )
          this.$router.replace({ name: 'formQuestions' })
        })
        .catch(err => {
          this.form.$busy = false

          this.form.choices = this.form.choices.map(choice => ({
            name: choice.name,
            resources: choice.resources ? choice.resources : []
          }))

          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async addFormQuestion() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.form.type_id = this.form.milestone
      this.form.choices = this.form.choices.map(choice => ({
        name: choice.name,
        resource_ids: choice.resources ? choice.resources.map(r => r.id) : []
      }))

      this.createFormQuestion(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Form question successfully added!', 'success')
          this.$router.replace({ name: 'formQuestions' })
        })
        .catch(err => {
          this.form.$busy = false

          this.form.choices = this.form.choices.map(choice => ({
            name: choice.name,
            resources: choice.resources ? choice.resources : []
          }))

          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    removeFormQuestion() {
      this.form.$busy = true
      this.deleteFormQuestion(this.form.id)
        .then(() => {
          this.form.$busy = false
          this.deleteDialog = false
          this.showSnackbar('Form question deleted successfully!')
          this.$router.replace({ name: 'formQuestions' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 404) {
            this.deleteDialog = false
            this.showSnackbar(
              'The form question you are trying to delete is no longer existing.',
              'error'
            )
          }
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  created() {
    if (this.$router.currentRoute.name === 'formQuestion.details') {
      this.getFormQuestion()
    }
    if (this.milestones.length === 0) {
      this.$store.dispatch('milestone/getMilestones')
    }
  },

  destroyed() {
    this.clearFormQuestionDetails()
  },

  watch: {
    searchResourceName: debounce(async function(searchInput) {
      if (this.resourceIsLoading) return

      this.resourceIsLoading = true

      await this.getResources({
        search: searchInput,
        sort: 'title'
      })

      this.resourceIsLoading = false
      this.resourceInput = ''
    }, 200),

    $route() {
      this.getFormQuestion()
    }
  }
}
</script>
